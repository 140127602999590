@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* GREEN COLOR */
  --color-green-bg: #DCEFD6;
  --color-green-shadow: #63A202;

  --color-green-light: #9fcb29; /* 300:a9d533 200:b3df3d 100:bde947 */
  --color-green: #95C11F;
  --color-green-dark: #52AD33;
  --color-green-contrast: #000000;

  /* ORANGE COLOR */
  --color-orange-bg: #fbdeb3;

  --color-orange-light: #FFB546;
  --color-orange: #F29100;
  --color-orange-dark: #EC6001;
  --color-orange-contrast: #000000;

  /* PINK COLOR */
  --color-pink-light: #F43D7C;
  --color-pink: #D60A52;
  --color-pink-dark: #C40006;
  --color-pink-contrast: #000000;

  /* BLUE COLOR */
  --color-blue-shadow: #009BC6;

  --color-blue-light: #29c3e1; /* 300:33cdeb 200:3dd7f5 100:47e1ff */
  --color-blue: #1FB9D7;
  --color-blue-dark: #007896;
  --color-blue-darker: #293682;
  --color-blue-contrast: #000000;

  /* PURPLE COLOR */
  --color-purple-light: #9741A9;
  --color-purple: #7D368C;
  --color-purple-dark: #592664;
  --color-purple-contrast: #FFFFFF;
}

[data-theme='dark'] {
  /* GREEN COLOR */
  --color-green-bg: #DCEFD6;
  --color-green-shadow: #63A202;

  --color-green-light: #9fcb29; /* 300:a9d533 200:b3df3d 100:bde947 */
  --color-green: #95C11F;
  --color-green-dark: #52AD33;
  --color-green-contrast: #000000;

  /* ORANGE COLOR */
  --color-orange-bg: #fbdeb3;
  
  --color-orange-light: #FFB546;
  --color-orange: #F29100;
  --color-orange-dark: #EC6001;
  --color-orange-contrast: #000000;

  /* PINK COLOR */
  --color-pink-light: #F43D7C;
  --color-pink: #D60A52;
  --color-pink-dark: #C40006;
  --color-pink-contrast: #000000;

  /* BLUE COLOR */
  --color-blue-shadow: #009BC6;

  --color-blue-light: #29c3e1; /* 300:33cdeb 200:3dd7f5 100:47e1ff */
  --color-blue: #1FB9D7;
  --color-blue-dark: #007896;
  --color-blue-darker: #293682;
  --color-blue-contrast: #000000;

  /* PURPLE COLOR */
  --color-purple-light: #9741A9;
  --color-purple: #7D368C;
  --color-purple-dark: #592664;
  --color-purple-contrast: #FFFFFF;
}

/* .cover-month:before {
  content: "";
  background: var(--color-orange);
  display: inline-block;
  width: 100%;
  padding-bottom: 100%;
  margin-right: 5px;
  position: absolute;
  z-index:-1;
  top: -2.4rem;
  left: -2.4rem;
}

.cover-month:after {
  content: "&";
  color: var(--color-pink);
  font-size: 50rem;
  position: absolute;
  right: -50%;
  top: 30%;
  z-index: -1;
} */

@layer components {
  .issue-resume p:not(:nth-last-child(-n + 3)) {
    @apply mb-6;
  }
  .issue-resume p:nth-last-child(3) {
    @apply mb-10;
  }
  .issue-resume p:nth-last-child(-n + 2):has(i) {
    @apply text-right;
  }
  .issue-resume ul, .issue-resume ol {
    @apply list-disc mb-6;
  }
  .issue-resume li {
    @apply ml-10 list-disc;
  }
  .issue-resume li:not(:last-child) {
    @apply mb-3;
  }

  .issue-content h3 {
    @apply text-orange font-bold uppercase mt-6;
  }
  .issue-content-special h2, .issue-content h2 {
    @apply text-blue text-3xl font-bold uppercase mt-8 -mb-3;
  }
  .issue-content-special h3 {
    @apply text-pink font-bold uppercase mt-6;
  }
  .issue-content-special h3 + h3 {
    @apply !mt-0;
  }

  /* LE PLUS TEMPLATE ARTICLE */
  .le-plus {
    @apply text-lg flex flex-col;
  }
  .le-plus h2 {
    @apply text-4xl text-blue-darker flex mt-10 mb-3 font-bold uppercase;
  }
  .le-plus h3 {
    @apply text-3xl mt-4 mb-3 font-semibold uppercase;
  }
  .le-plus > p:not(:has(+ ul)):not(:has(+ ol)) {
    @apply mb-6;
  }
  .le-plus li {
    @apply mb-3;
  }
  .le-plus ol {
    @apply list-decimal;
  }
  .le-plus a {
    @apply underline text-blue-darker;
  }
  .le-plus img {
    @apply self-center my-5 max-h-[34rem];
  }
}