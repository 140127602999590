.issue-slider {
  div, a, img {
    height: 100%;
  }
  :global .slick-list { 
    overflow: visible;
    overflow-x: clip;
    margin: 0 -7px; 
    & .slick-slide {
      transition: transform 0.5s linear;
      > div, > div > a {
        display: flex !important;
        align-items: center;
        padding: 0 10px; 

        img {
          height: 100% !important;
          width: auto;
        }
      }
      &.slick-center {
        transform: scale(1.2);
        // height: 100% !important;
      }
    }
  }
}